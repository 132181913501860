import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      allDataJson {
        nodes {
          menu {
            contacts_link
          }
        }
      }
    }
  `)

  return (
    <Row>
      <Navbar expand="lg" className={"bg-atf py-2 shadow-sm justify-content-between container-fluid"}>
        <Link to="/" className={"p-2"}>
          <img src={data.logo.publicURL} style={{ width: "240px" }} className={"mb-0"} alt={"Venditori Auto"} />
        </Link>
        <a href="mailto:commerciale@venditoriauto.com" className={"p-2 text-va"}>{data.allDataJson.nodes[0].menu.contacts_link}</a>
      </Navbar>
    </Row>
  )
}

export default Header
