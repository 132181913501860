import * as React from "react"
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import SEO from "../components/seo";
import Footer from "../components/footer";
import {graphql, useStaticQuery} from "gatsby";
import Header from "../components/header";


// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      },
      website: file(relativePath: { eq: "website.svg" }) {
        publicURL
      },
      fiat: file(relativePath: { eq: "fiat.svg" }) {
        publicURL
      },
      bmw: file(relativePath: { eq: "bmw.svg" }) {
        publicURL
      },
      line1_problems: file(relativePath: { eq: "line1-problems.svg" }) {
        publicURL
      },
      line2_problems: file(relativePath: { eq: "line2-problems.svg" }) {
        publicURL
      },
      allDataJson {
        nodes {
          atf {
            title
            sub_title
          }
          problems {
            title
            list {
              title
              content
              offset
            }
          }
          features {
            title
            list {
              title
              content
            }
          }
          platform {
            title
            sub_title
            list
          }
          timeline {
            title
            list {
              title
              content
            }
          }
        }
      }
    }
  `)

  return (
    <Container fluid className={""}>
      <SEO title="Home" />

      <Header/>

      <Row className={"bg-atf text-center text-white py-5"}>
        <Col>
          <h1 className={""} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].atf.title}}/>
          <p className={"fw-lighter mt-5"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].atf.sub_title}}/>
        </Col>
      </Row>
      <Row className={"bg-atf-img text-center"}>
        <Col>
          <img src={data.website.publicURL} style={{ width: "640px", maxWidth: "70%", }} className={"m-auto website-img"} alt={"Sito Web"}/>
        </Col>
      </Row>

      <Row className={"mt-5"}>
        <Col xs={12} style={{ marginTop: "-5vw" }} className={"overflow-hidden"}>
          <img src={data.line1_problems.publicURL} style={{ width: "110%", margin: "0 -20px" }} className={""} alt={""}/>
        </Col>
        <Col xs={12} className={"text-center bg-problems-img-top"}>
          <h1 className={"text-uppercase fw-lighter"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].problems.title}}/>
        </Col>
        <Col xs={12} className={""}>
          <Row className={"align-items-center pt-5 bg-va"}>
            <Col md={4} className={"fiat-overflow"}>
              <img src={data.fiat.publicURL} style={{ width: "140%", margin: "0 -20%" }} className={""} alt={""}/>
            </Col>
            <Col className={"my-5"}>
              { data.allDataJson.nodes[0].problems.list.map((prob, i) => (
                <Row>
                  <Col xs={{ span: 10, offset: 1 }} md={{ span: 3, offset: prob.offset }} className={"text-center"}>
                    <h2 className={""} dangerouslySetInnerHTML={{__html: prob.title}}/>
                    <p className={""} dangerouslySetInnerHTML={{__html: prob.content}}/>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Col>
        <Col xs={12} className={"bg-problems-img-bottom"}>
          <img src={data.line2_problems.publicURL} style={{ width: "65%", marginLeft: "-20px", }} className={""} alt={""}/>
        </Col>
      </Row>

      <Row className={"my-5 py-5"}>
        <Col xs={12} className={"text-center mb-5"}>
          <h1 className={"fw-lighter"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].features.title}}/>
        </Col>
        { data.allDataJson.nodes[0].features.list.map((feat, i) => (
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: i%2 ? 0 : 2 }} className={"my-3"}>
            <h2 className={""} dangerouslySetInnerHTML={{__html: feat.title}}/>
            <p className={""} dangerouslySetInnerHTML={{__html: feat.content}}/>
          </Col>
        ))}
      </Row>

      <Row className={"bg-bmw-img text-center pb-5"}>
        <Col>
          <img src={data.bmw.publicURL} style={{ width: "640px", maxWidth: "70%", marginTop: "-4rem"}} className={"mx-auto website-img"} alt={""}/>
        </Col>
        <Col xs={12}>
          <h1 className={"display-3 text-uppercase fw-lighter text-va fw-bold"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].platform.title}}/>
        </Col>
        <Col xs={12} className={""}>
          <h4 className={"fw-lighter text-va fst-italic"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].platform.sub_title}}/>
        </Col>
        <Col xs={12} className={"my-4"}>
          { data.allDataJson.nodes[0].platform.list.map((feat, i) => (
            <h4 className={"fw-light"}><span className="text-va">+</span> <span className="text-va-muted" dangerouslySetInnerHTML={{__html: feat}}/></h4>
          ))}
        </Col>
        <Col xs={12} className={"my-5"}>
          <h3 className={"text-uppercase fw-lighter text-va fw-bold"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].timeline.title}}/>
        </Col>
        <Col xs={12} className={"mb-5 timeline-bg"}>
          { data.allDataJson.nodes[0].timeline.list.map((mile, i) => (
            <Row className={"my-4"}>
              <Col xs={{ span: 9, offset: 1 }} md={{ span: 4, offset: i%2 ? 2 : 6 }} className={i%2 ? 'text-start text-md-end timeline-left-bg' : 'text-start timeline-right-bg'}>
                <h2 className={"text-va"} dangerouslySetInnerHTML={{__html: mile.title}}/>
                <p className={"text-va-muted"} dangerouslySetInnerHTML={{__html: mile.content}}/>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>

      <Footer/>

    </Container>
  )
}

export default IndexPage
