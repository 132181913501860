import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Container} from "react-bootstrap";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      allDataJson {
        nodes {
          footer {
            title
            company 
            general_conditions
          }
        }
      }
    }
  `)

  return (
    <Row className={"footer align-items-center justify-content-center bg-atf darker text-dark"}>
      <Col xs={12} className={"text-center"}>
        <div className={"my-4"}>
          <img src={data.logo.publicURL} style={{ width: "240px" }} className={"m-auto"} alt={"Venditori Auto"}/>
        </div>
      </Col>
      <Col xs={12} className={"text-center"}>
        <div className={"my-4"}>
          <h6 className={""} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].footer.title}}/>
          <p className={"my-4"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].footer.company}}/>
          <u><a className={"text-va"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].footer.general_conditions}}/></u>
        </div>
      </Col>
    </Row>
  )
}

export default Footer
